<template>
  <div class="programs">
    <ProgramsThreeColumns
      :cfgTitle="`${cfg.nameCategory}`"
      :cfgCategory="`${cfg.category}`"
    />
  </div>
</template>

<script>
import ProgramsThreeColumns from '@/components/Custom/Programs/ThreeColumns.vue';

export default{
  name: 'Programs',
  components:{
    ProgramsThreeColumns
  },
  data() {
    return {
      currentPrograms: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    }
  },

}
</script>